<template>
  <v-flex xs12 sm12 md6>
    <v-card class="mx-auto">
      <v-container>
        <v-row>
          <v-col cols="1">
            <IconBase><lampIcon v-if="isLamp" /> </IconBase>
          </v-col>
          <v-col cols="5">
            <div class="text-h6 title">{{ device.displayName }}</div>
          </v-col>
          <v-col class="d-flex" cols="6">
            <v-spacer></v-spacer>
            <v-switch
              inset
              v-if="device.isLive"
              @change="handleSwitchChange"
              v-model="switchValue"
            ></v-switch>
          </v-col>
        </v-row>
      </v-container>

      <v-card-text>
        <div v-if="device.isLive">
          <v-row class="mb-4" justify="space-between">
            <v-col class="text-left">
              <span
                class="display-3 font-weight-light"
                v-text="dimmerValue"
              ></span>
              <span class="subheading font-weight-light mr-1">%</span>
            </v-col>
          </v-row>
          <v-slider
            v-model="dimmerValue"
            @change="handleSliderChange"
            step="5"
            thumb-label
            ticks
          ></v-slider>
        </div>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="amber lighten-1" v-bind="attrs" v-on="on">
              mdi-alert
            </v-icon>
          </template>
          <span class="white-text">{{ $t("messages.alert.cannotConnectToTopic") }}</span>
        </v-tooltip>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <favoriteIcon :favorite="device.isFavorite" @click="toggleFavorite" />
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
import favoriteIcon from "../icons/favorite.vue";
import IconBase from "../icons/IconBase";
import lampIcon from "../icons/IconLamp";

export default {
  props: ["device"],
  components: { favoriteIcon, IconBase, lampIcon },
  data: () => {
    return {
      dimmerValue: null,
      switchValue: false
    };
  },
  computed: {
    deviceValue() {
      const topicIndex = this.device.topics.findIndex(
        (topic) => topic.type === "subscriber"
      );
      return this.device.topics[topicIndex].value;
    },
    isLamp() {
      return this.device.deviceType === "Lamp";
    }
  },
  created() {
    this.dimmerValue = this.deviceValue;
  },
  watch: {
    deviceValue() {
      this.dimmerValue = this.deviceValue;
    },
    dimmerValue() {
      this.switchValue = this.dimmerValue > 0 ? true : false;
    }
  },
  methods: {
    handleSliderChange() {
      this.sendToBroker();
    },
    handleSwitchChange() {
      this.dimmerValue = this.switchValue === false ? 0 : 100;
      this.sendToBroker();
    },
    sendToBroker() {
      const pubTopicName = this.getTopicNameToPublishOn();

      this.$store.getters["mqtt/mqttClient"].publish(
        pubTopicName,
        "" + this.dimmerValue,
        { qos: 1, retain: true },
        (err) => {
          if (!err) {
            //TODO
          } else {
            //TODO
          }
        }
      );
    },
    async toggleFavorite() {
      await this.$store.dispatch("custSetup/toggleFavorite", {
        deviceId: this.device.id,
      });
    },
    getTopicNameToPublishOn() {
      const pubTopicIndex = this.device.topics.findIndex(
        (topic) => topic.type === "publisher"
      );
      return this.device.topics[pubTopicIndex].topicName;
    }
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin: 0 !important;
}

.v-application--is-ltr .v-input--selection-controls__input,
.v-input--selection-controls__input {
  margin-right: 0 !important;
}

.title {
  color: #607d8b;
}

</style>